import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLocalNav,
  CYoutube,
  CFadeSlider,
  CSectTitle,
  CImgCard02,
  CBtnList,
  CBenefitCard02,
  LAboutContact,
} from '../../components/_index';
import '../../assets/_sass/page/about.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'ABOUT US',
            sub: '当ホテルについて',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '当ホテルについて',
            },
          }}
        />
      </CJumbotron>
      <div className="p_about">
        <section className="u_mb90 u_mt90">
          <LWrap>
            <CLocalNav
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_localnav.png',
                    alt: '',
                  },
                  link: {
                    href: '/about/access/',
                  },
                  title: {
                    main: 'アクセス',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_localnav02.png',
                    alt: '',
                  },
                  link: {
                    href: '/faq/',
                  },
                  title: {
                    main: 'よくあるご質問',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_localnav03.png',
                    alt: '',
                  },
                  link: {
                    href: 'https://contact.royalparkhotels.co.jp/yrph/contact?_ifbs-yrph_contact_form=s1_Step1',
                    blank: true,
                    icon: 'blank',
                  },
                  title: {
                    main: 'お問合せ',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_localnav04.png',
                    alt: '',
                  },
                  link: {
                    href: '/about/access/surroundings/',
                  },
                  title: {
                    main: '周辺案内',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_localnav05.png',
                    alt: '',
                  },
                  link: {
                    href: 'https://www.royalparkhotels.co.jp/the-club/',
                    blank: true,
                    icon: 'blank',
                  },
                  title: {
                    main: '会員',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="">
          <div className="parallax p_bg"></div>
          <LWrap exClass="l_sect u_colorWhite">
            <h2 className="c_headingLv2 u_mtExLarge">
              超高層階からの眺望
              <br />
              きめ細やかなホスピタリティ
            </h2>
            <p className="c_sectLead">
              空の表情を間近で感じたことがありますか？
              <br />
              季節、時間、天候、方角でさまざまに変容する空を、日本で一番高い場所にあるホテルでご体験ください。
              <br />
              心からの笑顔とおもてなしでお待ちしております。
              <br />
              空と隣り合うホテル
              <br />
              ようこそ、Yokohama Sky Resortへ
            </p>
            <CYoutube videoId="tYiDVC_Hnvc" />
          </LWrap>
        </section>
        <section className="">
          <div className="parallax p_bg02"></div>
          <LWrap exClass="l_sect u_colorWhite">
            <h2 className="c_headingLv2 u_mtExLarge">
              横浜ランドマークタワー
              <br />
              52Fから67Fに位置する客室
            </h2>
            <p className="c_sectLead">
              すべてのお部屋がゆったりと寛げるインターナショナルサイズ。
              <br />
              広がる空と港町ヨコハマの眺望とご一緒に、非日常の時間をお過ごしください。
            </p>
            <CFadeSlider
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_club.png',
                    alt: 'ガーデンサイド',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_club02.png',
                    alt: 'ガーデンサイド2',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_club03.png',
                    alt: 'ガーデンサイド3',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_club04.png',
                    alt: 'ガーデンサイド4',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_club05.png',
                    alt: 'ガーデンサイド5',
                  },
                },
              ]}
            />
            <CBtnList
              data={[
                {
                  label: 'ご宿泊',
                  link: {
                    href: '/stay/',
                  },
                  color: 'bgWhite',
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="">
          <div className="parallax p_bg03"></div>
          <LWrap exClass="l_sect u_colorWhite">
            <h2 className="c_headingLv2 u_mtLarge">
              地上277m、ランドマークタワー
              <br />
              最上階のスカイラウンジ
              <br />
              68Fに位置する和・洋・中のレストラン
            </h2>
            <p className="c_sectLead">
              ビジネスやプライベートのさまざまなシーンにご利用いただける飲食施設をご用意。
              <br />
              大切な日の会食に、ご接待にご利用ください。
            </p>
            <CFadeSlider
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_restaurants.png',
                    alt: 'ガーデンサイド',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_restaurants02.png',
                    alt: 'ガーデンサイド',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_restaurants03.png',
                    alt: 'ガーデンサイド',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_restaurants04.png',
                    alt: 'ガーデンサイド',
                  },
                },
              ]}
            />
            <CBtnList
              data={[
                {
                  label: 'レストラン＆バー',
                  link: {
                    href: '/restaurants/',
                  },
                  color: 'bgWhite',
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="">
          <div className="parallax p_bg04"></div>
          <LWrap exClass="l_sect u_colorWhite">
            <h2 className="c_headingLv2 u_mtLarge">
              横浜市内最大級の大宴会場
              <br />
              日本で一番高い場所にある
              <br />
              スカイバンケットルーム
            </h2>
            <p className="c_sectLead">
              正統派のホテルウエディング、世界からのお客様をお迎えするコンベンション。
              <br />
              さまざまなご要望にお応えできる大小12の宴会場がございます。
            </p>
            <CFadeSlider
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_banquet.png',
                    alt: '70F スカイバンケットルーム',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_banquet02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_banquet03.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CBtnList
              data={[
                {
                  label: '宴会・会議',
                  link: {
                    href: '/banquet/',
                  },
                  color: 'bgWhite',
                },
                {
                  label: 'ウエディング',
                  link: {
                    href: '/wedding/',
                  },
                  color: 'bgWhite',
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="p_spHide">
          <div className="parallax p_bg05"></div>
          <LWrap exClass="l_sect u_colorWhite">
            <h2 className="c_headingLv2 u_mtLarge">
              記念日は横浜ロイヤルパークホテルで
            </h2>
            <p className="c_sectLead">
              出会ったあの日を記念して。
              <br />
              大切な方のお誕生日に、気持ちを込めてあなたらしく。
            </p>
            <CFadeSlider
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_anniversary.png',
                    alt: '70F スカイバンケットルーム',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_anniversary02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_anniversary03.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CBtnList
              data={[
                {
                  label: 'お祝い・記念日',
                  link: {
                    href: '/anniversary/',
                  },
                  color: 'bgWhite',
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mtLarge u_mb90">
          <LWrap>
            {/* <CSectTitle
              exClass="u_mtLarge"
              title={{ ja: 'フォトギャラリー', en: 'Photo Gallery' }}
            /> */}
            <CImgCard02
              exClass="last_sp_only"
              col={5}
              colSp={3}
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_about17.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about18.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about19.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about20.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about21.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about__sp.png',
                    alt: '',
                  },
                },
              ]}
            />
            <h2 className="c_headingLv2 u_mtLarge">
              日常を忘れて、寛ぎのひとときを
            </h2>
            <p className="c_sectLead u_mb50_sp">
              充実の施設と行き届いたサービスで
              <br />
              スカイリゾートの時間をお楽しみください。
            </p>
            <CImgCard02
              col={3}
              colSp={2}
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_about22.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about23.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about24.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about25.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about26.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about27.png',
                    alt: '',
                  },
                },
              ]}
            />
            <h2 className="c_headingLv2 u_mtLarge">横浜観光の拠点に</h2>
            <p className="c_sectLead u_mb50_sp">
              横浜の周辺施設へのアクセスも抜群。
              <br />
              中華街へも、赤レンガ倉庫にも、山下公園へも、車なら10分以内、徒歩でも30分以内の立地です。
            </p>
            <CImgCard02
              col={3}
              colSp={2}
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_about28.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about29.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about30.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about31.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about32.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_about33.png',
                    alt: '',
                  },
                },
              ]}
            />
            <CBtnList
              exClass="u_mt90"
              data={[
                {
                  label: 'アクセス',
                  link: {
                    href: '/about/access/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_bgMarbleWhite" style={{ padding: '80px 0' }}>
          <LWrap>
            <CSectTitle
              title={{
                ja: 'ザ クラブ・ロイヤルパークホテルズのご案内',
                en: 'MEMBERSHIP',
              }}
            />
            <p className="c_sectLead u_mb40">
              ザ
              クラブ・ロイヤルパークホテルズは、全国のロイヤルパークホテルズでご利用いただける入会金・年会費無料の会員制度です。
              <br />
              ポイント特典やお得な割引などのさまざまなサービスがございます。
            </p>
            <CBenefitCard02
              data={[
                {
                  label: (
                    <>
                      Benefit <em>01</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>お得な</em>
                      <br />
                      宿泊プラン
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>02</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit02.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      レストラン・<sup>※</sup>
                      <br />
                      ショップ<em>割引</em>
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>03</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit03.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>ポイント</em>
                      <br />
                      特典
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>04</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit04.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>会員ステージ</em>
                      <br />
                      制度
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>05</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit05.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>アニバーサリー</em>
                      <br />
                      特典
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>06</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit06.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>優待施設</em>
                      <br />
                      での特典
                    </>
                  ),
                },
              ]}
            />
            <p>※対象のレストラン・ショップは会員サイトをご確認ください。</p>
            <CBtnList
              data={[
                {
                  label: '会員サイト',
                  link: {
                    href: 'https://www.royalparkhotels.co.jp/the-club/',
                    blank: true,
                  },
                  icon: 'blank',
                },
              ]}
            />
            <div className="u_mtMedium u_mbMedium">
              <ul className="c_noteList">
                <li>画像は全てイメージです。</li>
              </ul>
            </div>
          </LWrap>
        </section>
      </div>
      <LAboutContact currentIndex={0} />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
